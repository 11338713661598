import React from "react";
import theme from "theme";
import { Theme, Link, Image, Text, Span, Icon, LinkBox, Box, Section, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaTelegramPlane, FaRegEye } from "react-icons/fa";
import { MdFace, MdMonetizationOn } from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoIosAlarm } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				NEWCHANNEL MEDIA
			</title>
			<meta name={"description"} content={"Сетка телеграм каналов и ботов с общей аудиторией >1МЛ на различные тематики: бизнес, музыка, кино, игровые  и познавательные каналы с ботами"} />
			<meta property={"og:title"} content={"NEWCHANNEL MEDIA"} />
			<meta property={"og:description"} content={"Сетка телеграм каналов и ботов с общей аудиторией >1МЛ на различные тематики: бизнес, музыка, кино, игровые  и познавательные каналы с ботами"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/bgcalc.jpg?v=2021-11-16T14:45:01.711Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Section
			background="linear-gradient(180deg,#2D2C2C 0%,#050505 100%)"
			min-height="100vh"
			padding="48px 0 48px 0"
			quarkly-title="HeroBlock"
			sm-overflow-y="hidden"
			sm-overflow-x="hidden"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override
				slot="SectionContent"
				position="relative"
				justify-content="space-between"
				align-items="center"
				sm-justify-content="center"
				sm-align-items="center"
			/>
			<Components.Animation
				test
				animation="Vibrate 1"
				position="absolute"
				top="-60px"
				left="20%"
				right="auto"
				bottom="auto"
				duration="40s"
				sm-left="0%"
			>
				<Image src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/Ellipse4.png?v=2021-11-16T14:53:05.822Z" width="50%" />
			</Components.Animation>
			<Components.Animation
				test
				animation="Vibrate 2"
				position="absolute"
				top="auto"
				left="20%"
				right="auto"
				duration="40s"
				bottom="-80px"
			>
				<Image src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/Ellipse1.png?v=2021-11-16T14:54:53.792Z" width="50%" />
			</Components.Animation>
			<Components.Animation
				test
				animation="Flicker"
				position="absolute"
				top="40px"
				left="auto"
				right="-20%"
				duration="40s"
				bottom="auto"
			>
				<Image src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/Ellipse3.png?v=2021-11-16T14:49:32.118Z" width="50%" />
			</Components.Animation>
			<Box display="flex" flex-direction="column" align-items="center">
				<Image
					src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/N.png?v=2021-10-18T12:12:12.403Z"
					width="300px"
					height="300px"
					lg-width="250px"
					lg-height="250px"
					md-width="180px"
					md-height="180px"
					sm-height="140px"
					sm-width="140px"
				/>
				<Text
					font="normal 500 60px/1.2 --fontFamily-googleMontserrat"
					color="--white"
					margin="20px 0px 12px 0px"
					text-align="center"
					display="flex"
					background="rgba(0, 0, 0, 0) url() 0% 0% /auto repeat scroll padding-box"
					lg-font="normal 500 50px/1.2 --fontFamily-googleMontserrat"
					md-font="normal 500 40px/1.2 --fontFamily-googleMontserrat"
					as="h1"
					sm-font="normal 500 28px/1.2 --fontFamily-googleMontserrat"
				>
					NEWCHANNEL MEDIA
				</Text>
				<Text
					font="--base"
					color="--white"
					margin="0px 0px 86px 0px"
					md-text-align="center"
					width="80%"
					text-align="center"
					md-margin="0px 0px 50px 0px"
					sm-width="90%"
				>
					Сетка телеграм каналов и ботов с общей аудиторией{" "}
					<Span
						opacity="1"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						font="500 16px/24px Inter, sans-serif"
					>
						&gt;1МЛН
					</Span>
					{" "}на различные тематики: бизнес, музыка, кино, игровые  и познавательные каналы с ботами
				</Text>
				<LinkBox
					flex-direction="row"
					padding="16px 36px 16px 36px"
					border-color="--color-secondary"
					border-width="1px"
					border-style="solid"
					border-radius="8px"
					href="#catalog"
					hover-background="--color-secondary"
					hover-color="--dark"
					color="--light"
					position="relative"
					z-index="9"
					transition="background-color 0.2s --transitionTimingFunction-easeInOut 0s"
					margin="0px 0px 40px 0px"
					sm-padding="16px 20px 16px 20px"
				>
					<Text
						color="--white"
						font="--headline3"
						margin="0px 10px 0px 0px"
						md-font="normal 400 20px/1.2 &quot;Montserrat&quot;, sans-serif"
						sm-font="normal 400 16px/1.2 &quot;Montserrat&quot;, sans-serif"
					>
						Перейти в каталог
					</Text>
					<Icon
						category="fa"
						icon={FaTelegramPlane}
						color="--white"
						size="40PX"
						md-font="32px sans-serif"
						sm-font="25px sans-serif"
					/>
				</LinkBox>
			</Box>
			<LinkBox
				href="#catalog"
				hover-opacity=".65"
				transition="opacity 0.3s --transitionTimingFunction-easeInOut 0s"
				hover-position="relative"
				hover-z-index="9"
				position="relative"
				z-index="9"
				width="50px"
			>
				<Image height="50px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/arrow.svg?v=2021-10-18T19:22:27.917Z" />
			</LinkBox>
		</Section>
		<Section
			id="catalog"
			background="--color-dark"
			padding="120px 0 120px 0"
			quarkly-title="Tabs"
			nout-padding="80px 0 80px 0"
			lg-padding="80px 0 60px 0"
			sm-padding="80px 0 30px 0"
			sm-overflow-x="hidden"
			sm-overflow-y="hidden"
			overflow-y="hidden"
			overflow-x="hidden"
		>
			<Override slot="SectionContent" align-items="center" justify-content="center" position="relative" />
			<Components.Animation
				test
				animation="Shake"
				position="absolute"
				top="120px"
				left="0px"
				right="auto"
				bottom="auto"
				duration="20s"
				width="80%"
			>
				<Image src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/Ellipse%205.png?v=2021-10-21T12:16:53.848Z" width="80%" />
			</Components.Animation>
			<Components.Animation
				test
				animation="Ping"
				position="absolute"
				right="0px"
				bottom="-220px"
				duration="20s"
				top="auto"
				left="auto"
				width="80%"
			>
				<Image src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/Ellipse%206.png?v=2021-10-21T18:27:54.871Z" width="80%" />
			</Components.Animation>
			<Components.Tabs defaultTab="bots" width="100%" position="relative">
				<Override slot="TabPanels" width="100%" />
				<Override slot="Tablist" align-items="center" justify-content="center" grid-template-columns="repeat(2, 1fr)" />
				<Override slot="Tab bots">
					Боты
				</Override>
				<Override
					slot="Tab channels"
					opacity=".5"
					border-color="rgba(235, 209, 91, 0)"
					border-style="solid"
					border-width="1px"
				>
					Каналы
				</Override>
				<Override
					slot="Tab"
					justify-self="center"
					align-self="center"
					margin="0px 16px 0px 16px"
					background="rgba(0, 119, 204, 0)"
					font="--lead"
					padding="15px 45px 15px 45px"
					focus-box-shadow="none"
					color="--white"
					sm-font="normal 400 18px/1.5 &quot;Montserrat&quot;, sans-serif"
					sm-margin="0px 9px 0px 9px"
					sm-padding="15px 24px 15px 24px"
				/>
				<Override
					slot="Tab :active"
					opacity="1"
					border-width="1px"
					border-style="solid"
					border-color="--color-secondary"
					border-radius="8px"
				/>
				<Icon category="md" icon={MdFace} />
				<Components.Tab tabId="bots" width="100%">
					<Structure padding="108px 0px 88px 0px" cells-number-total="8" cells-number-group="8" sm-padding="108px 0px 50px 0px">
						<Override
							slot="Content"
							margin="0px 0px 0px 0px"
							width="100%"
							grid-gap="124px 24px"
							nout-grid-template-columns="repeat(3, 1fr)"
							grid-template-columns="repeat(4, 1fr)"
							lg-grid-template-columns="repeat(2, 1fr)"
							md-grid-template-columns="repeat(1, 1fr)"
							sm-grid-gap="106px"
						>
							<Override slot="cell-0">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/mixvk_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/mixvkbot.png?v=2021-10-20T12:05:16.612Z" />
										<Image
											width="32px"
											height="32px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/fire-orange%201.svg?v=2021-10-19T12:51:29.722Z"
											position="absolute"
											bottom="7px"
											right="16px"
											left="auto"
											top="auto"
										/>
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											VK Music Bot
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Музыкальный бот
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											330 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											30 час
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											6000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-1">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/icinema_bot.png?v=2021-10-19T12:27:26.949Z" />
										<Image
											width="32px"
											height="32px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/fire-orange%201.svg?v=2021-10-19T12:51:29.722Z"
											position="absolute"
											bottom="7px"
											right="16px"
											left="auto"
											top="auto"
										/>
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											ICINEMA Bot
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Кино бот
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											175 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											21 час
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											4000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-2">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/iEruditBot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/cheerudit.png?v=2021-10-20T12:07:58.012Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Чё, эрудит?
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Познавательный бот
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											34 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											8 часов
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											3000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-3">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/CubeDice_Bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/dice.png?v=2021-10-20T12:12:50.335Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Игральные кости
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Игровой бот
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											23 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											8 часов
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											4000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-4">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/Jackpot777bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/bandit.png?v=2021-10-20T12:14:23.021Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Игровой автомат
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Игровой бот
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											27 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											8 часов
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											4000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-5">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/creatormusbot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/creatormus.png?v=2021-10-20T12:23:46.340Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Creator Music Bot
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Бот конструктор
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											5 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											4 часов
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											2000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-6">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/iTikTokerBot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/tiktoker.png?v=2021-10-20T12:26:22.820Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											iTIKTOKER Bot
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Видео бот
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											2 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											1 час
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											500 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-7">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 24px 24px"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
									href="/"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/born.png?v=2021-10-20T12:34:00.655Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Рождение бота
										</Link>
										<Image
											margin="10px 0px 0px 0px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading.png?v=2021-10-20T12:30:53.972Z"
											max-width="180px"
											height="4px"
											min-height="0px"
											min-width="0px"
										/>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Image
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading-2.png?v=2021-10-20T12:32:18.706Z"
											min-width="none"
											min-height="none"
											width="10px"
											height="10px"
										/>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="io" icon={IoIosAlarm} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Время рассылки
											</Text>
										</Box>
										<Image
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading-2.png?v=2021-10-20T12:32:18.706Z"
											min-width="none"
											min-height="none"
											width="10px"
											height="10px"
										/>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Image
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading-2.png?v=2021-10-20T12:32:18.706Z"
											min-width="none"
											min-height="none"
											width="10px"
											height="10px"
										/>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
										opacity="0.3"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
						</Override>
					</Structure>
				</Components.Tab>
				<Components.Tab tabId="channels" width="100%">
					<Structure padding="108px 0px 88px 0px" cells-number-total="8" cells-number-group="8" sm-padding="108px 0px 60px 0px">
						<Override
							slot="Content"
							margin="0px 0px 0px 0px"
							width="100%"
							grid-gap="124px 24px"
							nout-grid-template-columns="repeat(3, 1fr)"
							grid-template-columns="repeat(4, 1fr)"
							lg-grid-template-columns="repeat(2, 1fr)"
							md-grid-template-columns="repeat(1, 1fr)"
							sm-grid-gap="106px"
						>
							<Override slot="cell-0">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/mixvk_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/vkm.png?v=2021-10-21T19:43:04.251Z" />
										<Image
											width="32px"
											height="32px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/fire-orange%201.svg?v=2021-10-19T12:51:29.722Z"
											position="absolute"
											bottom="7px"
											right="16px"
											left="auto"
											top="auto"
										/>
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											VK Music Bot
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Музыкальный канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											245 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											20 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											4000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-1">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/icinema-news.png?v=2021-10-21T19:44:33.266Z" />
										<Image
											width="32px"
											height="32px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/fire-orange%201.svg?v=2021-10-19T12:51:29.722Z"
											position="absolute"
											bottom="7px"
											right="16px"
											left="auto"
											top="auto"
										/>
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											iCINEMA News
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Кино канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											110 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											10 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											3000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-2">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/finder.jpg?v=2021-10-21T19:48:46.832Z" border-radius="50%" />
										<Image
											width="32px"
											height="32px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/fire-orange%201.svg?v=2021-10-19T12:51:29.722Z"
											position="absolute"
											bottom="7px"
											right="16px"
											left="auto"
											top="auto"
										/>
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Finder Music
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Музыкальный канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											110 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											11 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											2500 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-3">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/vkm2.jpg?v=2021-10-22T19:37:08.943Z" border-radius="50%" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Music search
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Музыкальный канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											71 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											8 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											2000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-4">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/momsbiz.jpg?v=2021-10-22T19:57:50.461Z" border-radius="50%" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Мамкин Бизнесмен
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Бизнес канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											41 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											2 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											5000 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-5">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/wild.jpg?v=2021-10-22T19:58:31.119Z" border-radius="50%" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Nat Geo WILD
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Познавательный канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											41 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											4 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											3500 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-6">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 12px 24px"
									href="https://t.me/icinema_bot"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/macro.jpg?v=2021-10-22T20:00:49.822Z" border-radius="50%" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Nat Geo Macro
										</Link>
										<Text color="--grey" margin="6px 0px 0px 0px" font="--base">
											Познавательный канал
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											15 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											2 тыс
										</Text>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Text color="--grey" margin="0px 0px 0px 0px" font="--base">
											1800 руб
										</Text>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
							<Override slot="cell-7">
								<LinkBox
									border-width="1px"
									border-style="solid"
									background="--color-darkL2"
									border-color="--color-darkL1"
									border-radius="8px"
									padding="0px 24px 24px 24px"
									target="_blank"
									color="--light"
									transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
									hover-color="--secondary"
									hover-border-color="rgba(235, 209, 91, 0.5)"
									hover-background="--color-darkL2 linear-gradient(180deg,--color-darkL2 0%,rgba(235, 209, 91, 0.12) 100%)"
									href="https://t.me/EuroSoccer24"
								>
									<Box position="relative" top="-75px" href="https://t.me/icinema_bot" target="_blank">
										<Image width="150px" height="150px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/born.png?v=2021-10-20T12:34:00.655Z" />
									</Box>
									<Box
										display="flex"
										align-items="center"
										flex-direction="column"
										justify-content="center"
										position="relative"
										top="-51px"
										margin="0px 0px 20px 0px"
									>
										<Link
											href="https://t.me/icinema_bot"
											position="static"
											font="--headline4"
											text-decoration-line="initial"
											hover-color="inherit"
											target="_blank"
											color="--white"
										>
											Рождение канала
										</Link>
										<Image
											margin="10px 0px 0px 0px"
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading.png?v=2021-10-20T12:30:53.972Z"
											max-width="180px"
											height="4px"
											min-height="0px"
											min-width="0px"
										/>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="bs" icon={BsFillPeopleFill} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Аудитория
											</Text>
										</Box>
										<Image
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading-2.png?v=2021-10-20T12:32:18.706Z"
											min-width="none"
											min-height="none"
											width="10px"
											height="10px"
										/>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="fa" icon={FaRegEye} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Охват на пост
											</Text>
										</Box>
										<Image
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading-2.png?v=2021-10-20T12:32:18.706Z"
											min-width="none"
											min-height="none"
											width="10px"
											height="10px"
										/>
									</Box>
									<Box
										display="flex"
										flex-direction="row"
										align-items="center"
										justify-content="space-between"
										position="relative"
										top="-45px"
										width="100%"
										margin="0px 0px 12px 0px"
									>
										<Box display="flex" align-items="center">
											<Icon category="md" icon={MdMonetizationOn} color="--secondary" size="20px" />
											<Text color="--grey" margin="0px 0px 0px 10px" font="--base">
												Цена рассылки
											</Text>
										</Box>
										<Image
											src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/loading-2.png?v=2021-10-20T12:32:18.706Z"
											min-width="none"
											min-height="none"
											width="10px"
											height="10px"
										/>
									</Box>
									<Link
										href="https://t.me/niktwix"
										slot="Button Open"
										font="--base"
										color="--white"
										text-decoration-line="initial"
										padding="12px 32px 12px 32px"
										border-width="1px"
										border-style="solid"
										border-color="--color-secondary"
										border-radius="8px"
										position="relative"
										top="-24px"
										transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
										hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
										hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
										hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
										background="rgba(0, 119, 204, 0)"
										target="_blank"
										opacity="0.3"
									>
										Заказать
									</Link>
								</LinkBox>
							</Override>
						</Override>
					</Structure>
				</Components.Tab>
			</Components.Tabs>
			<LinkBox
				href="#feedback"
				hover-opacity=".65"
				transition="opacity 0.3s --transitionTimingFunction-easeInOut 0s"
				hover-position="relative"
				hover-z-index="9"
				position="relative"
				z-index="9"
				width="50px"
			>
				<Image height="50px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/arrow.svg?v=2021-10-18T19:22:27.917Z" />
			</LinkBox>
		</Section>
		<Section padding="24px 0 60px 0" overflow-x="hidden" overflow-y="hidden">
			<Override slot="SectionContent" position="relative" align-items="center" />
			<Image
				src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/bubble.png?v=2021-10-21T18:48:56.641Z"
				position="absolute"
				bottom="auto"
				left="-100px"
				height="300px"
				right="auto"
				top="450px"
				width="300px"
			/>
			<Image
				src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/star_fav_favourite_icon_182132%201.png?v=2021-10-21T19:03:48.544Z"
				position="absolute"
				bottom="auto"
				left="auto"
				top="150px"
				right="0px"
				width="150px"
			/>
			<Structure
				id="feedback"
				padding="48px 0px 48px 0px"
				cells-number-total="5"
				cells-number-group="5"
				position="relative"
				sm-padding="48px 0px 20px 0px"
			>
				<Override
					slot="Content"
					grid-gap="64px"
					grid-template-columns="repeat(2, 1fr)"
					width="100%"
					nout-grid-gap="32px"
					md-grid-template-columns="1fr"
				>
					<Override slot="Cell 0" grid-column="1 / span 2" />
					<Override slot="cell-0">
						<Text
							margin="0px 0px 0px 0px"
							color="--white"
							font="--headline2"
							text-align="center"
							md-font="normal 500 32px/1.2 &quot;Montserrat&quot;, sans-serif"
							sm-font="normal 500 26px/1.2 &quot;Montserrat&quot;, sans-serif"
						>
							ОТЗЫВЫ КЛИЕНТОВ
						</Text>
						<Text
							margin="13px 0px 30px 0px"
							color="--white"
							font="normal 200 20px/1.5 --fontFamily-googleMontserrat"
							text-align="center"
							nout-margin="13px 0px 30px 0px"
							md-font="normal 200 16px/1.5 --fontFamily-googleMontserrat"
						>
							Перед покупкой рекламы вы всегда можете ознакомиться{" "}
							<br />
							с реальными отзывами наших постоянных клиентов
						</Text>
					</Override>
					<Override slot="cell-1">
						<Box
							className="feedback"
							background="linear-gradient(110.48deg, rgba(40, 40, 40, 0.85) 0.37%, rgba(6, 6, 6, 0) 100%)"
							padding="50px 50px 50px 50px"
							border-radius="8px"
							border-width="1px"
							border-style="solid"
							border-color="--color-darkL1"
							align-items="center"
							display="flex"
							flex-direction="column"
							justify-content="center"
							position="relative"
							z-index="9"
							height="100%"
						>
							<Box
								display="flex"
								width="100px"
								height="100px"
								background="--color-orange"
								border-radius="50%"
								justify-content="center"
								align-items="center"
							>
								<Text font="--headline1" color="--white">
									D
								</Text>
							</Box>
							<Text color="--color-light" font="--lead" margin="11px 0px 11px 0px">
								Бизнес и инвестиции
							</Text>
							<Text color="--white" font="--base" margin="0px 0px 29px 0px" text-align="center">
								Шикосно залетели, 1000 новых юзеров, полностью прошедших регистрацию. 1 юзер обошелся в 5р, что гораздо ниже среднего, советую💓
							</Text>
							<Link
								href="https://t.me/newchannel_media/34"
								slot="Button Open"
								font="--base"
								color="--white"
								text-decoration-line="initial"
								padding="12px 32px 12px 32px"
								border-width="1px"
								border-style="solid"
								border-color="--color-secondary"
								border-radius="8px"
								position="static"
								top="-24px"
								transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
								hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
								hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
								hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
								background="rgba(0, 119, 204, 0)"
								target="_blank"
							>
								Проверить отзыв
							</Link>
						</Box>
					</Override>
					<Override slot="cell-2">
						<Box
							className="feedback"
							background="linear-gradient(110.48deg, rgba(40, 40, 40, 0.85) 0.37%, rgba(6, 6, 6, 0) 100%)"
							padding="50px 50px 50px 50px"
							border-radius="8px"
							border-width="1px"
							border-style="solid"
							border-color="--color-darkL1"
							align-items="center"
							display="flex"
							flex-direction="column"
							justify-content="center"
							position="relative"
							z-index="9"
							height="100%"
						>
							<Box
								display="flex"
								width="100px"
								height="100px"
								background="--color-primary"
								border-radius="50%"
								justify-content="center"
								align-items="center"
							>
								<Text font="--headline1" color="--white">
									M
								</Text>
							</Box>
							<Text color="--color-light" font="--lead" margin="11px 0px 11px 0px">
								Шоу-бизнес
							</Text>
							<Text color="--white" font="--base" margin="0px 0px 29px 0px" text-align="center">
								Заказывали рекламу трека  в музыкальном боте и на канале. Результатом осталась довольна, трек попал
								<br />
								в топы чартов через несколько часов. Супер!!
							</Text>
							<Link
								href="https://t.me/newchannel_media/33"
								slot="Button Open"
								font="--base"
								color="--white"
								text-decoration-line="initial"
								padding="12px 32px 12px 32px"
								border-width="1px"
								border-style="solid"
								border-color="--color-secondary"
								border-radius="8px"
								position="static"
								top="-24px"
								transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
								hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
								hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
								hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
								background="rgba(0, 119, 204, 0)"
								target="_blank"
							>
								Проверить отзыв
							</Link>
						</Box>
					</Override>
					<Override slot="cell-3">
						<Box
							className="feedback"
							background="linear-gradient(110.48deg, rgba(40, 40, 40, 0.85) 0.37%, rgba(6, 6, 6, 0) 100%)"
							padding="50px 50px 50px 50px"
							border-radius="8px"
							border-width="1px"
							border-style="solid"
							border-color="--color-darkL1"
							align-items="center"
							display="flex"
							flex-direction="column"
							justify-content="center"
							position="relative"
							z-index="9"
							height="100%"
						>
							<Box
								display="flex"
								width="100px"
								height="100px"
								background="url(https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/tadi.jpg?v=2021-10-22T14:51:12.518Z) 0% 0%/100%"
								border-radius="50%"
								justify-content="center"
								align-items="center"
							/>
							<Text color="--color-light" font="--lead" margin="11px 0px 11px 0px">
								Музыкант
							</Text>
							<Text color="--white" font="--base" margin="0px 0px 29px 0px" text-align="center">
								Большой отзыв от музыканта, который рекламировал у нас свой трек, который попал в топы, и с которым связались продюсеры
							</Text>
							<Link
								href="https://t.me/newchannel_media/19"
								slot="Button Open"
								font="--base"
								color="--white"
								text-decoration-line="initial"
								padding="12px 32px 12px 32px"
								border-width="1px"
								border-style="solid"
								border-color="--color-secondary"
								border-radius="8px"
								position="static"
								top="-24px"
								transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
								hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
								hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
								hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
								background="rgba(0, 119, 204, 0)"
								target="_blank"
							>
								Проверить отзыв
							</Link>
						</Box>
					</Override>
					<Override slot="cell-4">
						<Box
							className="feedback"
							background="linear-gradient(110.48deg, rgba(40, 40, 40, 0.85) 0.37%, rgba(6, 6, 6, 0) 100%)"
							padding="50px 50px 50px 50px"
							border-radius="8px"
							border-width="1px"
							border-style="solid"
							border-color="--color-darkL1"
							align-items="center"
							display="flex"
							flex-direction="column"
							justify-content="center"
							position="relative"
							z-index="9"
							height="100%"
						>
							<Box
								display="flex"
								width="100px"
								height="100px"
								background="url(https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/1.jpg?v=2021-10-21T19:36:25.304Z) 0% 0%/100%"
								border-radius="50%"
								justify-content="center"
								align-items="center"
							/>
							<Text color="--color-light" font="--lead" margin="11px 0px 11px 0px">
								Криптовалюта
							</Text>
							<Text color="--white" font="--base" margin="0px 0px 29px 0px" text-align="center">
								Даров, спасибо, зашло по 24 руб, хороший результат (целевой 25 руб для крипты у меня) 👍
							</Text>
							<Link
								href="https://t.me/newchannel_media/45"
								slot="Button Open"
								font="--base"
								color="--white"
								text-decoration-line="initial"
								padding="12px 32px 12px 32px"
								border-width="1px"
								border-style="solid"
								border-color="--color-secondary"
								border-radius="8px"
								position="static"
								top="-24px"
								transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
								hover-background="linear-gradient(267.06deg, #EBD15B 0%, #B27F2A 100%)"
								hover-box-shadow="0px 0px 25px rgba(235, 209, 91, 0.15)"
								hover-font="normal 600 14px/1.5 &quot;Montserrat&quot;, sans-serif"
								background="rgba(0, 119, 204, 0)"
								target="_blank"
							>
								Проверить отзыв
							</Link>
						</Box>
					</Override>
					<Override slot="Cell 0th" md-grid-column="auto / auto" />
				</Override>
			</Structure>
			<LinkBox
				href="#calc"
				hover-opacity=".65"
				transition="opacity 0.3s --transitionTimingFunction-easeInOut 0s"
				hover-position="relative"
				hover-z-index="9"
				position="relative"
				z-index="9"
				width="50px"
				margin="30px 0px 0px 0px"
			>
				<Image height="50px" src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/arrow.svg?v=2021-10-18T19:22:27.917Z" />
			</LinkBox>
		</Section>
		<Section
			id="calc"
			padding="98px 0 36px 0"
			background="linear-gradient(180deg,rgba(5, 5, 5, 0.27) 0%,rgba(5, 5, 5, 0.27) 100%) 0 0 no-repeat,--color-dark url(https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/bcfoncalc.png?v=2021-11-18T15:04:12.364Z) center center/cover repeat scroll padding-box"
			lg-padding="60px 0 36px 0"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override slot="SectionContent" align-items="center" position="relative" />
			<Image
				src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/shopping_cart_caddy_icon_185995%201.png?v=2021-11-18T15:42:24.309Z"
				position="absolute"
				bottom="30px"
				left="-140px"
				height="300px"
				right="auto"
				width="240px"
			/>
			<Image
				src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/discount_prize_sale_tag_tags_icon_188572%201.png?v=2021-11-18T15:41:53.445Z"
				position="absolute"
				bottom="auto"
				left="auto"
				top="110px"
				right="-110px"
				width="250px"
				z-index="9"
				lg-top="80px"
				md-width="200px"
				md-right="-80px"
				md-top="70px"
				sm-top="100px"
				sm-width="180px"
				sm-right="-70px"
			/>
			<Text
				color="--white"
				font="--headline1"
				text-align="center"
				margin="0px 0px 0px 0px"
				nout-font="normal 500 48px/1.2 &quot;Montserrat&quot;, sans-serif"
				lg-font="normal 500 36px/1.2 &quot;Montserrat&quot;, sans-serif"
				md-font="normal 500 28px/1.2 &quot;Montserrat&quot;, sans-serif"
				sm-font="normal 500 24px/1.2 &quot;Montserrat&quot;, sans-serif"
			>
				СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ
			</Text>
			<Text
				color="--white"
				font="200 23px/1.3 --fontFamily-googleMontserrat"
				text-align="center"
				margin="13px 0px 60px 0px"
				width="80%"
				lg-font="200 18px/1.2 --fontFamily-googleMontserrat"
				lg-margin="13px 0px 40px 0px"
				md-width="100%"
				md-font="200 16px/1.2 --fontFamily-googleMontserrat"
				sm-font="200 14px/1.2 --fontFamily-googleMontserrat"
			>
				В несколько раз выгоднее взять рекламу оптом в нашей сетке, вы получите гарантированный результат, которому будете очень рады
			</Text>
			<Structure
				className="feedback"
				cells-number-group="2"
				cells-number-total="2"
				padding="24px 0px 60px 0px"
				width="100%"
			>
				<Override
					slot="Content"
					grid-gap="0px"
					background="linear-gradient(110.48deg,rgba(40, 40, 40, 0.85) 0.37%,rgba(6, 6, 6, 0.67) 100%)"
					border-radius="8px"
					border-style="solid"
					border-width="1px"
					border-color="--color-darkL1"
					width="100%"
					lg-grid-template-columns="1fr"
				>
					<Override slot="cell-0">
						<Text
							margin="0px 0px 13px 0px"
							font="normal 300 40px/1.2 --fontFamily-googleMontserrat"
							color="--white"
							text-align="center"
							sm-font="normal 300 30px/1.2 --fontFamily-googleMontserrat"
						>
							Боты
						</Text>
						<Text
							margin="0px 0px 13px 0px"
							font="normal 200 20px/1.5 --fontFamily-googleMontserrat"
							color="--white"
							text-align="center"
							lg-font="normal 200 16px/1.5 --fontFamily-googleMontserrat"
							width="80%"
						>
							<Span
								font="500 20px/30px Montserrat, sans-serif"
								lg-font="500 16px/30px Montserrat, sans-serif"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								Скидка 20%
							</Span>
							{" "}активируется минимум от 3 выбранных позиций
						</Text>
						<Image
							src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/Linecalc.png?v=2021-11-16T13:55:34.891Z"
							position="absolute"
							top="40px"
							right="-1.5px"
							min-width="none"
							lg-display="none"
						/>
						<Image
							src="https://uploads.quarkly.io/616d5f4e35c5c8001f7768a9/images/palka.png?v=2021-11-18T15:31:22.604Z"
							position="absolute"
							top="auto"
							right="auto"
							min-width="none"
							left="auto"
							bottom="0px"
							width="100%"
							display="none"
							lg-display="block"
						/>
						<Components.UCalc ucalcId="375565" width="100%" />
					</Override>
					<Override slot="Cell" padding="34px 34px 34px 34px" />
					<Override
						slot="Cell 0th"
						padding="34px 0px 34px 0px"
						flex-direction="column"
						display="flex"
						align-items="center"
						justify-content="center"
					/>
					<Override slot="Cell 0" position="relative" />
					<Override slot="cell-1">
						<Text
							margin="0px 0px 13px 0px"
							font="normal 300 40px/1.2 --fontFamily-googleMontserrat"
							color="--white"
							text-align="center"
							sm-font="normal 300 30px/1.2 --fontFamily-googleMontserrat"
						>
							Каналы
						</Text>
						<Text
							margin="0px 0px 13px 0px"
							font="normal 200 20px/1.5 --fontFamily-googleMontserrat"
							color="--white"
							text-align="center"
							lg-font="normal 200 16px/1.5 --fontFamily-googleMontserrat"
							width="80%"
						>
							<Span
								font="500 20px/30px Montserrat, sans-serif"
								lg-font="500 16px/30px Montserrat, sans-serif"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								Скидка 20%
							</Span>
							{" "}активируется минимум от 3 выбранных позиций
						</Text>
						<Components.UCalc ucalcId="378965" width="100%" />
					</Override>
					<Override
						slot="Cell 1st"
						padding="34px 0px 34px 0px"
						flex-direction="column"
						display="flex"
						align-items="center"
						justify-content="center"
					/>
				</Override>
			</Structure>
		</Section>
		<Section background="--color-darkL2" overflow-x="hidden" overflow-y="hidden">
			<Text color="rgba(255, 255, 255, 0.5)" text-align="center" font="normal 400 16px/1.5 --fontFamily-googleMontserrat" margin="0px 0px 0px 0px">
				По поводу рекламы обращаться{" "}
				<Link href="https://t.me/niktwix" color="--white" text-decoration-line="initial" target="_blank">
					@niktwix
				</Link>
				{" "}&{" "}
				<Link
					href="https://t.me/goalov"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--white"
					text-decoration-line="initial"
					target="_blank"
				>
					@goalov
				</Link>
			</Text>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"616d5f4e35c5c8001f7768a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n* {\n    scroll-behavior: smooth;\n    }\n.feedback {\nbackdrop-filter: blur(15px);\n}"}
			</style>
		</RawHtml>
	</Theme>;
});